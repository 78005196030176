<template>
    <div class="page-box s-page">
        <div class="s-header">
            <van-nav-bar
            :title="$t('mining.locked-order')"
            left-arrow
            @click-left="$router.go(-1)"
            >
            </van-nav-bar>
        </div>
        <div class="maincontent s-content">
            <van-tabs v-model="activeName" color="#004EA3" title-active-color="#004EA3" style="flex: 1;" class="my-tabs" @change="change">
                <van-tab :title="$t('mining.mining-list')" name="a">
                        <div class="content-box">
                            <div style="display: flex;margin-bottom: 1.25rem;">
                                <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: left;">{{$t('mining.miner-quantity')}}</div>
                                <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: center;">{{$t('mining.revenue-period')}}</div>
                                <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: right;">{{$t('mining.remaining-status')}}</div>
                            </div>
                            <div class="sub-list" id="scrollableDiv">
                                <div class="sub-row" v-for="(item, key) in list" :key="key">
                                    <div>
                                        <div>{{item.title}}</div>
                                        <div class="t2">{{item.total}}</div>
                                    </div>
                                    <div style="text-align: center;">
                                        <div>{{item.income}}</div>
                                        <div class="t2">{{item.times}}</div>
                                    </div>
                                    <div style="text-align: right;">
                                        <div>{{item.left_times}}</div>
                                        <div class="t2" style="color: #05C5A3;">{{item.status==='0'?'Mining':'end'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </van-tab>
                <van-tab :title="$t('mining.mining-log')" name="b">
                    <div class="content-box">
                        <div style="display: flex;margin-bottom: 1.25rem;">
                            <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: left;">{{$t('mining.settlement-time')}}</div>
                            <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: center;">{{$t('mining.miner')}}</div>
                            <div style="font-size: 0.69rem;color: #8796A5;flex: 1;text-align: right;">{{$t('mining.income')}}</div>
                        </div>
                        <div class="sub-list" id="scrollableDiv">
                            <div class="sub-row" style="align-items: center;" v-for="(item, key) in logs" :key="key">
                                <div>
                                    <div>{{item.settletime | dateformat}}</div>
                                </div>
                                <div style="text-align: center;">
                                    <div>{{item.title}}</div>
                                </div>
                                <div style="text-align: right;">
                                    <div>{{item.income}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      activeName: 'a',
      list: [],
      logs: []
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    // this.$nextTick(function() {
    //     const scrollableDiv = document.getElementById('scrollableDiv');
    //     scrollableDiv.addEventListener('scroll', function() {
    //     if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight) {
    //         console.log("已经滚动到底部");
    //     }
    //     });
    // })
  },
  methods: {
    async getList() {
      const { data } = await this.$http.get('/home/miner/mylist')
      console.log(data)
      if (data.code === 200) {
        this.list = data.data.list
      }
    },
    async getLog() {
      const { data } = await this.$http.get('/home/miner/mylog')
      console.log(data)
      if (data.code === 200) {
        this.logs = data.data.logs
      }
    },
    change() {
      if (this.activeName === 'a') {
        this.getList()
      }
      if (this.activeName === 'b') {
        this.getLog()
      }
    }
  }
}
</script>
  <style lang="less" scoped>
  .maincontent {
    background: #EFF1F3;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100vh;
}

.my-tabs {
 display: flex;
 flex-direction: column;
 overflow: hidden;
 /deep/ .van-tabs__content{
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
 }
 /deep/ .van-tab__pane{
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
 }
 .sub-list{
    flex: 1;
    overflow-y: auto;
 }
}
.content-box{
    flex: 1;
    padding: 0.94rem;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
.sub-row{
    display: flex;
    justify-content: space-between;
    background: #F7F7F7;
    border-radius: 0.19rem;
    margin-bottom: 0.63rem;
    padding: 0.63rem 1.06rem;
    >div{
        flex: 1;
        text-align: left;
        font-size: 0.69rem;
        color: #8796A5;
    }
    .t2{
        color: #333848;
        margin-top: 0.63rem;
    }
}
</style>
